.header-bar .p-0{
    padding: 0px !important;
}

.header-bar .m-0{
    margin: 0px !important;
}

.header-bar .mr-10{
    margin-bottom: 10px !important;
}

.header-bar .sub-title {
    color: #9CA1BF;
    font-size: 14px;
    padding-right: 12px;
}

.header-bar .sub-title .anticon{
    margin-right: 5px;
}



@media only screen and (max-width: 700px) {
    .header-bar .ant-avatar{
        height: 50px !important;
        width: 50px  !important;
    }

    h4{
        font-size: 14px !important;
    }

    .sub-title.role{
        display: none;
    }

    .sub-title .anticon{
        margin-right: 0px;
    }
}

