.card-customers .ant-card-extra{
    min-width: 360px;
}

.card-customers .ant-card-extra h5{
    display: inline-block;
    margin-right: 10px;
}

.card-customers .customer-fullname{
    margin: 0px !important;
}

.card-customers .search-input{
    width: 250px;
}

.card-customers .search-input .ant-input{
    border-left: none;
}

.card-customers .search-input .ant-input-group-addon{
    background: none;
}

.mr-15{
    margin-right: 15px;;
}

.new-task-card .ant-card-body{
padding: 0px;
}

/* Detail page */


.table-row-light {
    background-color: #e2e2e2;
}
.table-row-yellow {
    background-color: #faca2c;
}

@media only screen and (max-width: 700px) {
    .card-customers .ant-card-extra{
        min-width: 100%;
    }
}



