.mr-15 {
    margin-right: 15px;
    ;
}

.profile-avatar {
    margin: 20px;
}

.profile-avatar .ant-badge-status-success {
    height: 20px;
    width: 20px;
    bottom: 0;
    top: unset;
    background-color: #50CD89;
    left: 180px;
}

.ant-form-item .ant-form-item-label>label {
    font-size: 16px;
    font-weight: 600;
    margin-top: 6px;
}

.ant-checkbox+span {
    font-size: 16px;
}

.profile-statistic-card:where(.css-dev-only-do-not-override-nnuwmp).ant-card .ant-card-body {
    border-style: dashed;
    border-radius: 8px;
    border-width: 1px;
    border-color: #DBDFE9;
    padding: 10px 25px;
}

.ant-statistic-content {
    font-weight: 600;
    font-size: 18px;
}

:where(.css-dev-only-do-not-override-nnuwmp).ant-statistic .ant-statistic-title {
    order: 2;
    color: #B5B5C3;
    font-size: 14px;
    font-weight: 600;
}

.ant-statistic-content {
    order: 1;
}

.ant-statistic {
    display: grid;
}

.oneamz-primary-button {
    background-color: black;
    color: white;
}

.oneamz-primary-button:where(.css-dev-only-do-not-override-nnuwmp).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: black;
    color: white;
    border: none;

}

.edit-button-pp {
    left: -40px;
    top: -73px;
}

.profile-card {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    box-shadow: -7px 0 15px -5px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    padding: 20px;
    border: none;
    height: 800px;
}


.profile-table .ant-table-cell {
    background: white !important;
}