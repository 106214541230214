.task-detail {
    background: orange;
    color: white;
}

.task-detail:hover {
    background: orange !important;
    color: white !important;
}

.task-detail.ant-btn-default {
    background: green;
    color: white;
}

.task-detail.ant-btn-default:hover {
    background: green !important;
    color: white !important;
}

.task-detail-card .ant-divider-horizontal{
    margin: 16px 0;
}

.task-detail-card h4 {
    margin: 0px;
    padding: 0px;
}

.task-detail-card p {
    margin: 0px;
    padding: 0px;
}

.task-detail-card .ant-card-body {
    padding: 0px;
}

.task-detail-card :where(.css-dev-only-do-not-override-1kpmgu1).ant-switch.ant-switch-checked{
    background: #52c41a;
}

.task-detail-card :where(.css-dev-only-do-not-override-1kpmgu1).ant-switch:hover:not(.ant-switch-disabled).ant-switch-checked{
    background: #52c41a;
}

.task-detail-card :where(.css-dev-only-do-not-override-1kpmgu1).ant-switch{
    background: #d46b08;
}

.task-detail-card :where(.css-dev-only-do-not-override-1kpmgu1).ant-switch:hover:not(.ant-switch-disabled){
    background: #d46b08;
}