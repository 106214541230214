.card-reviews .ant-card-extra{
    width: 260px;
}

.card-reviews .ant-card-extra h5{
    display: inline-block;
    margin-right: 10px;
}

.card-reviews .customer-fullname{
    margin: 0px;
}