.learner-statistics .ant-tabs-nav-list {
    border-radius: 10px;
}

.learner-statistics .ant-tabs .ant-tabs-tab {
    background: #fff;
    color: white;
    font-size: 18px;
    width: 100%;
    text-align: center;
    margin: 0px !important;
}

.learner-statistics .ant-tabs .ant-tabs-tab:first-of-type {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.learner-statistics .ant-tabs .ant-tabs-tab:last-of-type{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.learner-statistics .ant-tabs-tab-btn {
    width: 100%;
    color: #808080 !important;
}

.learner-statistics .ant-tabs-tab-active {
    background: black !important;
}

.learner-statistics .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
}

.learner-statistics .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none;
}