/* :where(.css-dev-only-do-not-override-nnuwmp).ant-timeline.ant-timeline-label .ant-timeline-item-label{
    max-width: 115px;
} */

.oneamz-primary-button{
    background-color: black;
    color: white;
}

.oneamz-primary-button:where(.css-dev-only-do-not-override-nnuwmp).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
    background-color: black;
    color: white;
    border: none;

}