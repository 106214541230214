:root {
  --darkModeBg: black;
  --darkModeBg2: #161616;
  --darkModeBg3: #373737;
  --darkModeBg4: #515151;
  --darkModeBg0: #ffcb2b;
  --darkModeModalBg: #0e0e0e;
  --darkModeBorder: #353535;
  --darkModeCard: #0f0f0f;
  --darkModeBtn: #474747;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

.ant-picker-cell-selected .ant-picker-calendar-date-value {
  color: aliceblue !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #4189e78a !important;
  color: antiquewhite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.oneamz-layout .ant-layout-sider-children {
  background-color: black;
}

.ant-layout-content {
  border-radius: 12px;
}

.ant-layout {
  background: black;
}

.ant-btn-primary {
  background-color: black;
  color: white;
}

:where(.css-dev-only-do-not-override-nnuwmp).ant-btn-primary:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  background-color: black;
  color: white;
  border-color: black;
}

.onemaz-page-title {
  margin: 0px;
}

.active::before {
  display: block;
  position: absolute;
  height: 40px;
  width: 10px;
  left: -18px;
  content: " ";
  border-left: 5px solid white;
}

.ant-layout-sider {
  width: 70px !important;
  max-width: 70px !important;
  min-width: 0px !important;
}

:where(.css-dev-only-do-not-override-1kpmgu1).ant-layout
  .ant-layout-sider-zero-width-trigger {
  background: rgba(0, 0, 0, 1);
}

:where(.css-dev-only-do-not-override-1kpmgu1).ant-layout
  .ant-layout-sider-zero-width-trigger:hover::after {
  background: none;
}

.nav-link {
  position: relative;
}

.nav-tooltip {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: black;
  left: calc(100% + 5px);
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all 100ms ease-in;
  z-index: 1001;
}

.nav-link:hover .nav-tooltip {
  opacity: 1;
  visibility: visible;
  left: calc(100% + 15px);
}

:has(.dark) .ant-layout-content {
  background: var(--darkModeBg) !important;
  opacity: 1;
}

:has(.dark) .ant-layout-content * {
  color: #fff !important;
}

:has(.dark) .ant-layout-sider + .ant-layout {
  border-left: 1px solid var(--darkModeBorder);
}

:has(.dark) .ant-input-affix-wrapper,
:has(.dark) .ant-picker {
  background-color: #191919;
  border: 1px solid var(--darkModeBorder);
}

:has(.dark) .ant-picker {
  color: #fff;
}

:has(.dark) .ant-input-affix-wrapper .ant-input,
:has(.dark) .ant-picker input {
  background-color: transparent;
  color: #fff;
}

:has(.dark) .ant-btn-primary {
  background-color: #474747;
}

:has(.dark) :where(.css-dev-only-do-not-override-1kpmgu1).ant-switch {
  background-color: #212121 !important;
}

:has(.dark) .ant-table-wrapper .ant-table-thead > tr > th,
:has(.dark) .ant-table-wrapper .ant-table-thead > tr > td {
  background-color: var(--darkModeBg);
  border-color: #212121;
  color: #fff;
}

:has(.dark)
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: #212121;
}

:has(.dark)
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:has(.dark)
  .ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-placeholder:hover
  > th,
:has(.dark)
  .ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-placeholder:hover
  > td,
:has(.dark) .ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
  background-color: #212121;
}

:has(.dark) .ant-table-wrapper .ant-table-tbody > tr > th,
:has(.dark) .ant-table-wrapper .ant-table-tbody > tr > td {
  border-color: #212121;
}

:has(.dark)
  .ant-table-wrapper
  .ant-table-thead
  th.ant-table-column-has-sorters:hover {
  background-color: #101010;
}

:has(.dark) :where(.css-dev-only-do-not-override-1kpmgu1).ant-input {
  background-color: #191919;
  border: 1px solid var(--darkModeBorder);
  color: #fff;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: #191919;
  border: 1px solid var(--darkModeBorder);
}

:has(.dark) .ant-table-wrapper .ant-table-cell-fix-left,
:has(.dark) .ant-table-wrapper .ant-table-cell-fix-right {
  background-color: #212121;
}

:has(.dark) .ant-table-wrapper .ant-table-tbody > tr > th,
:has(.dark) .ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: #212121;
}

:has(.dark) .ant-table .active::before {
  display: none;
}

:has(.dark) .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > th,
:has(.dark) .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
:has(.dark)
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > th.ant-table-cell-row-hover,
:has(.dark)
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td.ant-table-cell-row-hover {
  background-color: #2d2d2d;
}

:has(.dark) :where(.css-dev-only-do-not-override-1kpmgu1).ant-select-dropdown {
  background-color: #212121;
  color: #fff;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-select-dropdown
  .ant-select-item {
  color: #fff;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #2d2d2d;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-card
  .ant-card-head {
  background-color: var(--darkModeBg);
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-input-group
  .ant-input-group-addon {
  border-color: #212121;
}

:has(.dark) :where(.css-dev-only-do-not-override-1kpmgu1).ant-btn-default {
  background-color: var(--darkModeBtn);
  border-color: #212121;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-pagination
  .ant-pagination-item-active {
  background-color: var(--darkModeBtn);
  border-color: #212121;
  color: #fff;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-pagination
  .ant-pagination-item-active
  a {
  color: #fff;
}

.css-dev-only-do-not-override-1kpmgu1.ant-tag-red {
  color: #cf1322 !important;
}

.css-dev-only-do-not-override-1kpmgu1.ant-tag-green {
  color: #389e0d !important;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #ffcb2b !important;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-tabs
  .ant-tabs-ink-bar {
  background-color: #ffcb2b;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-switch
  .ant-switch-inner {
  background-color: var(--darkModeCard);
}

:has(.dark) .css-dev-only-do-not-override-1kpmgu1.ant-tag-purple * {
  color: #531dab !important;
}

:has(.dark) .css-dev-only-do-not-override-1kpmgu1.ant-tag-orange * {
  color: #d46b08 !important;
}

:has(.dark) .ant-card {
  background-color: transparent;
  color: #fff;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-switch
  .ant-switch-inner {
  background-color: transparent;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-badge.ant-badge-status
  .ant-badge-status-text {
  color: #fff;
}

.loginRight {
  background-color: #fff;
}

:has(.dark) .loginRight {
  background-color: var(--darkModeCard);
  color: #fff;
}

:has(.dark) :where(.css-dev-only-do-not-override-1kpmgu1).ant-form {
  color: #fff;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-input-affix-wrapper
  .ant-input-prefix,
:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-input-affix-wrapper
  .ant-input-suffix {
  color: #fff;
}

:has(.dark) :where(.css-dev-only-do-not-override-1kpmgu1).ant-checkbox-wrapper {
  color: #fff;
}

:has(.dark) :where(.css-dev-only-do-not-override-1kpmgu1) a {
  color: #fff;
}

.rowItem {
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
}

:has(.dark) .rowItem {
  border-color: #212121;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-modal
  .ant-modal-content {
  background-color: var(--darkModeModalBg);
  border: 1px solid var(--darkModeBorder);
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-modal
  .ant-modal-content
  * {
  color: #fff;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-modal
  .ant-modal-header {
  background-color: transparent;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-modal-root
  .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.8);
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-picker-dropdown
  .ant-picker-panel-container {
  background-color: var(--darkModeBg2);
  color: #fff;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-picker-dropdown
  .ant-picker-header
  button,
:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-picker-dropdown
  .ant-picker-content
  th,
:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-picker-dropdown
  .ant-picker-content
  td {
  color: #fff;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: var(--darkModeBg3);
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
:where(.css-dev-only-do-not-override-1kpmgu1).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before,
:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1kpmgu1).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1kpmgu1).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background: var(--darkModeBg0) !important;
  color: var(--darkModeBg);
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-drawer
  .ant-drawer-content {
  background-color: var(--darkModeBg2);
  color: #fff;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-drawer
  .ant-drawer-title,
:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-drawer
  .ant-drawer-close {
  color: #fff;
}

:has(.dark) .ant-alert-info {
  border: none;
  background-color: #1e40af;
}

:has(.dark) .ant-alert-info .ant-alert-message,
:has(.dark) .ant-alert-info .ant-alert-description {
  color: rgba(0, 0, 0, 0.88) !important;
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-alert
  .ant-alert-close-icon
  .anticon-close {
  color: #fff;
}

:has(.dark) .ant-alert-warning {
  border: none;
  background-color: #b57225;
}

:has(.dark) .ant-alert-warning .ant-alert-message {
  color: #fff;
}

:has(.dark) .ant-alert-success {
  border: none;
  background-color: #007e33;
}

:has(.dark) .ant-alert-success .ant-alert-message {
  color: #fff;
}

:has(.dark) .ant-alert-error {
  border: none;
  background-color: #f8d7da;
}

:has(.dark) .ant-alert-error .ant-alert-message {
  color: #991b1b !important;
}

.page-title {
  font-size: 24px;
  color: #808080;
}

:has(.dark) .page-title {
  color: #fff;
}

:has(.dark)
  :where(
    .css-dev-only-do-not-override-1kpmgu1
  ).ant-picker-calendar.ant-picker-calendar-full
  .ant-picker-panel {
  background-color: var(--darkModeBg2);
}

:has(.dark) :where(.css-dev-only-do-not-override-1kpmgu1).ant-picker-calendar {
  background-color: var(--darkModeBg3);
}

:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-drawer
  .ant-drawer-content
  .ant-card,
:has(.dark)
  :where(.css-dev-only-do-not-override-1kpmgu1).ant-drawer
  .ant-drawer-content
  .ant-card-body {
  background-color: var(--darkModeBg2);
  border: none !important;
}

:has(.dark) .ant-form-item-label * {
  color: #fff !important;
}

:has(.dark) {
  .ant-picker-input *,
  .ant-picker-input *::placeholder,
  .ant-input-affix-wrapper .ant-input::placeholder,
  .ant-input::placeholder,
  .ant-picker-time-panel .ant-picker-time-panel-cell-inner {
    color: #fff !important;
  }

  .ant-picker-time-panel .ant-picker-time-panel-cell-selected,
  .ant-picker-time-panel
    .ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background-color: var(--darkModeBg0) !important;
  }

  .ant-picker-time-panel .ant-picker-time-panel-cell-selected,
  .ant-picker-time-panel
    .ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    color: #000 !important;
  }

  .ant-tag-orange {
    color: #d46b08 !important;
  }

  .acm-dashboard {
    background-color: transparent !important;
  }

  .acm-dashboard .ant-card {
    border: 1px solid var(--darkModeBorder);
    overflow: hidden;
  }

  .lesson-card-container,
  .border-bottom,
  .note-row,
  .ant-table-thead th,
  .ant-table-tbody td,
  .ant-table-tbody .ant-table-cell,
  .ant-table-container {
    border-color: var(--darkModeBorder) !important;
  }

  .ant-table-expanded-row-fixed::after {
    border-inline-end: 1px solid var(--darkModeBorder) !important;
  }

  .ant-tag-processing {
    color: #1677ff !important;
    & * {
      color: #1677ff !important;
    }
  }

  .ant-tag-success {
    color: #52c41a !important;
    & * {
      color: #52c41a !important;
    }
  }

  .ant-tag-error {
    color: #ff4d4f !important;
    & * {
      color: #ff4d4f !important;
    }
  }

  .ant-tag-warning {
    color: #faad14 !important;
    & * {
      color: #faad14 !important;
    }
  }

  .ant-tag-magenta {
    color: #c41d7f !important;
    & * {
      color: #c41d7f !important;
    }
  }

  .ant-tag-red {
    color: #cf1322 !important;
    & * {
      color: #cf1322 !important;
    }
  }

  .ant-tag-volcano {
    color: #d4380d !important;
    & * {
      color: #d4380d !important;
    }
  }

  .ant-tag-orange {
    color: #d46b08 !important;
    & * {
      color: #d46b08 !important;
    }
  }

  .ant-tag-gold {
    color: #d48806 !important;
    & * {
      color: #d48806 !important;
    }
  }

  .ant-tag-lime {
    color: #7cb305 !important;
    & * {
      color: #7cb305 !important;
    }
  }

  .ant-tag-green {
    color: #389e0d !important;
    & * {
      color: #389e0d !important;
    }
  }

  .ant-tag-yellow {
    color: #d4b106 !important;
    & * {
      color: #d4b106 !important;
    }
  }

  .ant-tag-cyan {
    color: #08979c !important;
    & * {
      color: #08979c !important;
    }
  }

  .ant-tag-blue {
    color: #0958d9 !important;
    & * {
      color: #0958d9 !important;
    }
  }

  .ant-tag-geekblue {
    color: #1d39c4 !important;
    & * {
      color: #1d39c4 !important;
    }
  }

  .ant-tag-purple {
    color: #531dab !important;
    & * {
      color: #531dab !important;
    }
  }

  .ant-popover-inner {
    background-color: var(--darkModeBg3);
    color: #fff !important;
  }

  .ant-popover-inner * {
    color: #fff !important;
  }

  .ant-popover .ant-popover-arrow:before {
    background-color: var(--darkModeBg3);
  }

  /* .ant-tabs .ant-tabs-tab {

    }

    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {

    } */

  .ant-segmented .ant-segmented-item,
  .ant-segmented {
    background-color: var(--darkModeBg2) !important;
  }

  .ant-segmented .ant-segmented-item-selected {
    background-color: var(--darkModeBg3) !important;
  }

  .ant-switch .ant-switch-handle::before {
    background-color: var(--darkModeBg0);
  }

  .ant-btn-primary {
    background-color: rgba(255, 203, 43, 0.9);
    color: #000 !important;

    &:hover {
      background-color: var(--darkModeBg0) !important;
    }

    & * {
      color: #000 !important;
    }
  }

  .nav-tooltip {
    background-color: var(--darkModeBg0);
    color: #000;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: var(--darkModeBg4) !important;
    border: none !important;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--darkModeBg0) !important;
    border: none !important;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #000 !important;
  }

  .ant-select-single .ant-select-selector {
    color: #fff;
  }

  .ant-picker:not(.ant-picker-disabled).ant-picker-status-error,
  .ant-picker:not(.ant-picker-disabled).ant-picker-status-error:not(
      [disabled]
    ):hover {
    background-color: var(--darkModeBg2);
  }

  .ant-pagination .ant-pagination-options-quick-jumper input {
    background-color: var(--darkModeBg2);
    border-color: var(--darkModeBorder);
  }

  .ant-tabs .ant-tabs-tab {
    color: #fff;
  }

  .ant-select-selection-placeholder {
    color: #fff !important;
  }

  .ant-modal .ant-modal-content {
    background-color: #1f1f1f;
    color: #fff;
  }

  .ant-modal .ant-modal-confirm-title {
    color: #fff !important;
  }

  .ant-select-selector {
    background-color: #141414 !important;
    border: 1px solid #424242 !important;
  }

  .ant-select-dropdown {
    color: rgba(255, 255, 255, 0.85) !important;
    background-color: #1f1f1f !important;
  }

  .ant-select-dropdown .ant-select-item {
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #111a2c !important;
  }

  .ant-btn-default {
    background-color: #141414 !important;
    border-color: #424242 !important;
    box-shadow: 0 2px 0 rgba(255, 255, 255, 0.04) !important;
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .learner-statistics .ant-tabs .ant-tabs-tab {
    background: #212121 !important;
  }
  .learner-statistics .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #373737 !important;
  }

  .ant-card {
    border: 1px solid #212121 !important;
  }
}

:has(.dark)
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > .ant-table-cell
  .column-group-title {
  border-color: var(--darkModeBorder) !important;
}

.ant-table-summary,
.summary-cell.ant-table-cell-fix-left,
.summary-cell.ant-table-cell-fix-right {
  background-color: var(--darkModeBg0) !important;
}

/* .ant-table-summary tr.summary-cell td:nth-last-child(-n+2) {
  position: sticky !important;
  right: 0 !important;
  z-index: 1 !important;
} */

.highlight-row-red td,
.highlight-row-red .ant-table-cell-fix-left,
.highlight-row-red .ant-table-cell-fix-right {
  background-color: #ffcccc !important;
}

:has(.dark) .highlight-row-red td,
:has(.dark) .highlight-row-red .ant-table-cell-fix-left,
:has(.dark) .highlight-row-red .ant-table-cell-fix-right {
  background-color: #b22222 !important;
}

.highlight-row-orange td,
.highlight-row-orange .ant-table-cell-fix-left,
.highlight-row-orange .ant-table-cell-fix-right {
  background-color: #ffd580 !important;
}

:has(.dark) .highlight-row-orange td,
:has(.dark) .highlight-row-orange .ant-table-cell-fix-left,
:has(.dark) .highlight-row-orange .ant-table-cell-fix-right {
  background-color: #ff8c00 !important;
}

.highlight-row-blue td,
.highlight-row-blue .ant-table-cell-fix-left,
.highlight-row-blue .ant-table-cell-fix-right {
  background-color: lightblue !important;
}

:has(.dark) .highlight-row-blue td,
:has(.dark) .highlight-row-blue .ant-table-cell-fix-left,
:has(.dark) .highlight-row-blue .ant-table-cell-fix-right {
  background-color: #2563eb !important;
}

.list-check-svg-icon {
  filter: invert(1);
}

:has(.dark) .list-check-svg-icon {
  filter: none;
}

.note-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 28px !important;
}

.dashboard-table .ant-table-header tr {
  background-color: rgba(255, 255, 255, 0) !important;
}

.dashboard-table .ant-table-header tr th {
  background-color: rgba(255, 255, 255, 0) !important;
  padding-bottom: 10px !important;
}

.dashboard-table .ant-table-body,
.dashboard-table {
  scrollbar-width: auto;
  scrollbar-color: auto;
}

.dashboard-table .ant-table-body::-webkit-scrollbar,
.dashboard-table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.dashboard-table .ant-table-body::-webkit-scrollbar-track,
.dashboard-table::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}

.dashboard-table .ant-table-body::-webkit-scrollbar-thumb,
.dashboard-table::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #9e9e9e;
}

.dashboard-card::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

.dashboard-card::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0) !important;
}

.dashboard-card::-webkit-scrollbar-thumb {
  border-radius: 6px !important;
  background-color: #9e9e9e !important;
}

.zebra-table .ant-table-tbody > tr:nth-child(odd) {
  background-color: #ededed !important;
}

.zebra-table .ant-table-row:nth-child(odd) .ant-table-column-sort {
  background-color: #ededed !important;
}

.zebra-table .ant-table-tbody > tr:nth-child(odd):hover {
  background-color: #ededed !important;
}

.zebra-table .ant-table-tbody > tr:nth-child(even) {
  background-color: #ffffff !important;
}

.zebra-table .ant-table-tbody > tr:nth-child(even):hover {
  background-color: #ffffff !important;
}

.zebra-table .ant-table-row:nth-child(even) .ant-table-column-sort {
  background-color: #ffffff !important;
}

.zebra-table .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  background-color: #e6e6e6 !important;
}

.summary-report-table .ant-table-body,
.summary-report-table {
  scrollbar-width: auto !important;
  scrollbar-color: auto !important;
}

.summary-report-table .ant-table-body::-webkit-scrollbar,
.summary-report-table::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

.summary-report-table .ant-table-body::-webkit-scrollbar-track,
.summary-report-table::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0) !important;
}

.summary-report-table .ant-table-body::-webkit-scrollbar-thumb,
.summary-report-table::-webkit-scrollbar-thumb {
  border-radius: 6px !important;
  background-color: #9e9e9e !important;
}

.custom-react-datepicker {
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
  display: inline-block;
  pointer-events: auto;
}

.custom-react-datepicker .react-datepicker {
  border: none;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
  display: inline-block;
  pointer-events: auto;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.custom-react-datepicker .react-datepicker__header {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.custom-react-datepicker .react-datepicker__day-name,
.custom-react-datepicker .react-datepicker__day,
.custom-react-datepicker .react-datepicker__current-month {
  font-size: 14px;
}

.custom-react-datepicker .react-datepicker__week--selected {
  color: #fff;
  border-radius: 8px;
  background-color: #2A528A;
}

.custom-react-datepicker .react-datepicker__day--selected {
  color: #fff;
  border-radius: 8px;
  background-color: #2A528A;
}
