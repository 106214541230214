.acm-dashboard .card {
    padding: 0;
    border-radius: 16px;
    min-height: 185px;
}

.acm-dashboard .card .ant-card-body {
    height: 180px;
}

.acm-dashboard .bottom-cards {
    margin-top: 16px;
}

.info-card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: -webkit-fill-available;
}

.acm-dashboard .title {
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.acm-dashboard .description {
    color: #D1D1D1;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    margin-top: 5px;
}

.acm-dashboard .count {
    color: #EEC73B;
    font-family: 'Montserrat', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: right;
    margin-bottom: 0;
    margin-top: 0;
}

.acm-dashboard .sales-card {
    padding: 0;
    border-radius: 16px;
    height: 100%;
    margin-bottom: 16px;
}

.acm-dashboard .date-range-info {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}

.acm-dashboard .sales-card-info {
    color: #F0CE54;
    font-size: 18px;
    line-height: 24px;
    margin-top: 0;
}

.acm-dashboard .ant-switch {
    width: 120px;
}

.acm-dashboard .ant-switch-checked .ant-switch-handle::before {
    top: 9px;
    bottom: -9px;
    right: 8px;
    left: -8px;
    background-color: #EEC73B;
}

.acm-dashboard .ant-switch-handle::before {
    top: 9px;
    bottom: -9px;
    right: -8px;
    left: 8px;
    background-color: #FF7474;

}

.acm-dashboard .card-customers .customer-card-title {
    font-size: 22px;
    color: #EEC73B;
    font-weight: 500;
}

.acm-dashboard .card-customers .customer-card-info {
    font-size: 14px;
}


@media (max-width: 768px) {
    .acm-dashboard .title {
        font-size: 16px;
    }

    .acm-dashboard .description {
        font-size: 12px;
    }

    .acm-dashboard .count {
        font-size: 24px;
    }

    .acm-dashboard .bottom-cards {
        margin-top: 0;
    }

    .acm-dashboard .card {
        margin-top: 16px;
    }

}

@media (min-width: 768px) and (max-width: 925px) {
    .acm-dashboard .title {
        font-size: 18px;
    }

    .acm-dashboard .description {
        font-size: 14px;
    }

    .acm-dashboard .count {
        font-size: 28px;
    }
}

.ant-table-thead .ant-table-selection-column .ant-checkbox {
    display: none;
}
.ant-checkbox-wrapper-disabled {
    opacity: 1 !important; 
    cursor: default!important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
    border-color: #d9d9d9 !important; 
    background-color: #fff !important;
    cursor: default!important;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff !important; 
    border-color: #1890ff !important; 
    cursor: default!important;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff !important; 
    cursor: default!important;
}



