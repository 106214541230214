.card {
  padding: 0;
  border-radius: 16px;
  
}
.customer-info .ant-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  content: "";
}
.customer-info .ant-card-body::before,
.customer-info .ant-card-body::after {
  content: none;
}

.title {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.sub-title {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.description {
  color: #d1d1d1;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
  margin-top: 5px;
}

.sales-card-link {
  text-decoration: underline;
  color: #000;
  font-weight: 600;
  font-size: 10px;
}

.count {
  color: #eec73b;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin-bottom: 0;
  margin-right: 10px;
  margin-top: 10px;
}

.container {
  display: flex;
  align-items: flex-end;
}

.lesson-card-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
}

.date-header {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.date-description {
  font-size: 12px;
  margin-top: 0;
}

.header {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  color: #212121;
}

.border-bottom {
  border-bottom: 1px solid #eeeeee;
}

.info-header {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.info-description {
  font-size: 12px;
  font-weight: 700;
  margin-top: 0;
}

.sales-card {
  padding: 0;
  border-radius: 16px;
  height: 100%;
  margin-bottom: 16px;
}

.sales-history-card-table-title {
  font-weight: bold;
  font-size: 12px;
  color: #b5b7c0;
}

.sales-history-card-table-container {
  border-bottom: 1px solid #f0f0f0;
  padding-top: 15px;
  padding-bottom: 15px;
}

.sales-history-card-table-text {
  font-size: 10px;
}

.date-range-info {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.sales-card-info {
  color: #f0ce54;
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
}

.note-row-title {
  padding: 10px;
}

.note-row {
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
}

.note-text {
  text-align: left;
  font-size: 12px;
}
.note-table-title {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  color: #b5b7c0;
}

.note-icon-container {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.icon-wrapper {
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 15px;
  margin-right: 5px;
}

.icon-wrapper:hover {
  background-color: #000;
  color: white;
}

.icon-wrapper.checked {
  background-color: #000;
  color: #fff;
}

.icon-wrapper.checked:hover {
  background-color: #fff;
  color: #000;
}

.icon-wrapper.checked > svg {
  color: white;
}

.notes-container {
  height: 300px;
  overflow-y: auto;
}
.call-history-card .ant-checkbox {
  align-self: start !important;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .title {
    font-size: 16px;
  }

  .description {
    font-size: 12px;
  }

  .count {
    font-size: 24px;
  }
}

@media (min-width: 768px) and (max-width: 925px) {
  .title {
    font-size: 18px;
  }

  .description {
    font-size: 14px;
  }

  .count {
    font-size: 28px;
  }
}
